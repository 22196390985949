<template>
  <div>
    <BTable
      class="table-bordered"
      :items="debtCompanyList.results"
      :fields="fields2"
      show-empty
      responsive
    >
      <template #empty>
        <empty />
      </template>

      <template #cell(pay_his)="props">
        <div v-if="props.item.BASIS">
          <div
            v-for="(e, index) in props.item.BASIS.PAY_HISTORY"
            :key="index"
          >
            <ul
              class="d-flex align-items-center justify-content-between mb-0"
            >
              <li style="font-size: 11px">
                {{ e.PAY_DATE }}
              </li>
              <small class="ml-50">
                {{ $_moneyFormatter(e.PAY_AMOUNT) }},
              </small>
            </ul>
          </div>
        </div>
      </template>

      <template #cell(debtors)="data">
        <div
          v-if="data.item.BORROWER"
          class="text-primary cursor-pointer"
          @click="collector(data)"
        >
          {{ data.item.BORROWER.FULL_NAME }}
        </div>
      </template>

      <template #cell(BASIS)="props">
        <div v-if="props.item.BASIS">
          {{ DOSTAGE(props.item.BASIS.DO_STAGE) }}
        </div>
      </template>

      <template #cell(SUM)="props">
        <span v-if="props.item.BASIS && props.item.BASIS.DEBT">
          {{ $_moneyFormatter(props.item.BASIS.DEBT) }}
        </span>
      </template>

      <template #cell(DEBT_STATUS)="props">
        <b-badge
          v-if="props.item.DEBT_STATUS"
          :variant="props.item.DEBT_STATUS"
        >
          {{ getTextDebtStatus(props.item.DEBT_STATUS) }}
        </b-badge>
      </template>
    </BTable>

    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap mr-1">Показать по</span>
        <b-form-select
          v-model="params.page_size"
          :options="[5, 10, 20]"
          class="mx-1"
          @change="refresh"
        />
        <span class="ml-1 text-nowrap"> строк( {{ debtCompanyList.count }} )</span>
      </div>
      <b-pagination
        v-model="params.page"
        :total-rows="debtCompanyList.count"
        :per-page="params.page_size"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @change="changePage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { fields2 } from '@/modules/portfolios/constants'
import Empty from '@components/empty.vue'
import {
  BTable, BFormSelect, BPagination, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    Empty,
    BTable,
    BFormSelect,
    BPagination,
  },
  props: {
    companyId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      fields2,
      params: {
        page: 1,
        page_size: 10,
      },
    }
  },
  computed: {
    ...mapState('portfolios', ['debtCompanyList']),
    ...mapState('collectorDetail', ['active']),
  },
  methods: {
    ...mapActions('portfolios', ['FETCH_SK_DEBT_COMPANY_LIST']),
    ...mapMutations('collectorDetail', ['SET_ACTIVE', 'SET_CALL']),

    refresh() {
      this.FETCH_SK_DEBT_COMPANY_LIST({
        CLAIMER_CRM_COMPANY_ID: this.companyId,
        ...this.params,
      })
    },

    collector(data) {
      this.SET_CALL(false)
      this.SET_ACTIVE(1)
      this.$router.push(`/collector/${data.item.id}`)
    },

    changePage(page) {
      this.params.page = page

      this.refresh()
    },

    DOSTAGE(value) {
      const message = {
        voluntary_payment: 'Добровольная оплата',
        claim: 'Претензия',
        judical_work: 'Судебная работа',
        executive_document: 'Исполнительный документ',
        executive_work: 'Исполнительное производство',
        stopped: 'Приостановлено',
        paid: 'Погашен',
      }

      return message[value] || ''
    },

    getTextDebtStatus(value) {
      const message = {
        secondary: 'Неправильный номер',
        primary: 'Связь прервалась',
        'light-danger': 'Отказ',
        dark: 'Банкрот',
        danger: 'Неперспективный',
        info: 'Не ответил',
        warning: 'Обещание',
      }

      return message[value] || ''
    },
  },
}
</script>
