<template>
  <div>
    <statistics />

    <b-row>
      <b-col class="col-md-2">
        <BFormGroup
          label="Поиск"
        >
          <BFormInput
            v-model="filter.search_borrower"
            type="search"
            @input="searchConstructor"
          />
        </BFormGroup>
      </b-col>

      <b-col class="col-md-3 text-right ml-auto">
        <b-button
          style="margin-top: 27px"
          variant="primary"
          @click="openAddModal"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить
        </b-button>
      </b-col>

      <b-col md="12">
        <b-overlay
          :show="loading"
          rounded="sm"
        >
          <b-card class="mt-1">
            <b-table
              class="border"
              :fields="fields"
              :items="claimantsData.results"
              show-empty
              responsive
            >
              <template #empty>
                <empty />
              </template>

              <template #cell(total_sum)="props">
                {{ props.item.total_sum ? $_moneyFormatter(props.item.total_sum) : '' }}
              </template>

              <template
                #row-details="row"
              >
                <b-overlay
                  v-if="tableItemId === row.item.id"
                  :show="show"
                  rounded="sm"
                >
                  <subTable
                    v-if="tableItemId === row.item.id"
                    :company-id="CLAIMER_CRM_COMPANY_ID"
                  />
                </b-overlay>
              </template>

              <template #cell(users)="props">
                <div class="d-flex align-items-center flex-wrap justify-content-center w-75 m-auto">
                  <b-badge
                    v-for="(item, index) in props.item.users"
                    :key="index"
                    variant="success"
                    class="mr-50 mb-50"
                  >
                    {{ item.first_name + ' ' + item.last_name }}
                  </b-badge>
                </div>
              </template>

              <template #cell(actions)="props">
                <div class="d-flex align-items-center justify-content-center">
                  <BButton
                    class="p-50 mr-50"
                    :variant="props.detailsShowing ? 'secondary' : 'success'"
                    @click="openSubCase(props)"
                  >
                    <feather-icon :icon="props.detailsShowing ? 'ChevronsUpIcon' : 'ChevronsDownIcon'" />
                  </BButton>
                  <BButton
                    class="p-50 mr-50"
                    variant="warning"
                    @click="editRow(props.item)"
                  >
                    <feather-icon icon="EditIcon" />
                  </BButton>
                  <BButton
                    class="p-50"
                    variant="danger"
                    @click="deleteData(props.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </BButton>
                  <b-button
                    variant="info"
                    class="p-50 ml-50"
                    @click="downloadXls(props.item)"
                  >
                    <FeatherIcon icon="DownloadIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card>

          <b-card>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0">
                <span class="text-nowrap mr-1">Показать по</span>
                <b-form-select
                  v-model="filter.page_size"
                  :options="['5', '10', '20']"
                  class="mx-1"
                  @change="refresh"
                />
                <span class="ml-1 text-nowrap"> строк( {{ claimantsData.count }} )</span>
              </div>
              <b-pagination
                v-model="filter.page"
                :total-rows="claimantsData.count"
                :per-page="filter.page_size"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <AddEditModal
      :modal-val="modalVal"
      @refresh="refresh"
    />

    <XlsModal
      :xls-value="xlsValue"
    />
  </div>
</template>
<script>
import {
  BButton, BCard, BCol, BFormGroup, BFormInput, BFormSelect, BPagination, BRow, BTable, BOverlay, BBadge,
} from 'bootstrap-vue'
import empty from '@/components/empty.vue'
import { mapActions, mapState } from 'vuex'
import { fields } from '@/modules/portfolios/constants'
import XlsModal from '@/modules/portfolios/components/xlsxModal.vue'
import statistics from '../components/statistics.vue'
import AddEditModal from '../components/AddModal.vue'
import subTable from './subTable.vue'

export default {
  components: {
    statistics,
    empty,
    AddEditModal,
    BButton,
    BTable,
    BCard,
    BFormSelect,
    BPagination,
    BFormInput,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BBadge,
    subTable,
    XlsModal,
  },
  data() {
    return {
      xlsValue: null,
      filter: {
        search_borrower: null,
        page: 1,
        page_size: 10,
      },
      modalVal: {},
      loading: false,
      fields,
      CLAIMER_CRM_COMPANY_ID: 0,
      show: false,
      tableItemId: 0,
    }
  },
  computed: {
    ...mapState('portfolios', ['claimantsData']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('portfolios', ['FETCH_CLAIMANT_PORT', 'FETCH_CLAIMANT_PORT_STATISTICS', 'DELETE_CLAIMANT_TYPE', 'FETCH_SK_DEBT_COMPANY_LIST']),

    async refresh() {
      try {
        this.loading = true
        await this.FETCH_CLAIMANT_PORT({ client_type: 'INDIVIDUAL', ...this.filter })
        await this.FETCH_CLAIMANT_PORT_STATISTICS(this.filter)
      } catch (e) {
        await this.$_errorToast(e)
        this.loading = false
      }
      this.loading = false
    },

    downloadXls(item) {
      this.xlsValue = item.COMPANY_ID
      this.$bvModal.show('xlsModal')
    },

    openSubCase(props) {
      if (props && props.detailsShowing) {
        props.toggleDetails()
      } else {
        this.show = true
        this.tableItemId = props.item.id
        props.toggleDetails()
        this.FETCH_SK_DEBT_COMPANY_LIST({
          CLAIMER_CRM_COMPANY_ID: props.item.COMPANY_ID,
          search: this.filter.search_borrower,
        })
          .then(() => {
            this.CLAIMER_CRM_COMPANY_ID = props.item.COMPANY_ID
            this.show = false
          })
          .catch(e => {
            this.show = false
            this.$_errorToast(e)
          })
      }
    },

    openAddModal() {
      this.modalVal = {}
      this.$nextTick(() => {
        this.$bvModal.show('AddEditModal')
      })
    },

    editRow(item) {
      this.modalVal = item
      this.$nextTick(() => {
        this.$bvModal.show('AddEditModal')
      })
    },

    deleteData(item) {
      this.$bvModal
        .msgBoxConfirm('Вы действительно хотите удалить эту запись?', {
          title: 'Удалить',
          okVariant: 'danger',
          okTitle: 'Подтвердить',
          cancelTitle: 'Отменить',
          hideHeaderClose: false,
          centered: true,
        })
        .then(success => {
          if (success) {
            this.DELETE_CLAIMANT_TYPE(item.COMPANY_ID).then(() => {
              this.filter.page = 1
              this.refresh()
            })
          }
        })
    },

    searchConstructor() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.filter.page = 1
        this.refresh()
      }, 600)
    },

    changePage(page) {
      this.filter.page = page
      this.refresh()
    },
  },
}
</script>
