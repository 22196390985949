<template>
  <b-modal
    id="AddEditModal"
    title="Портфели"
    centered
    no-close-on-backdrop
    ok-title="Сохранить"
    cancel-variant="outline-secondary"
    cancel-title="Отмена"
    responsive
    @show="openShowModal"
    @ok="onSubmit"
  >
    <validation-observer ref="claimantsValidation">
      <b-form-group label="Взыскатель">
        <validation-provider
          #default="{ errors }"
          name="Взыскатель"
          rules="required"
        >
          <b-form-input
            v-model="value.COMPANY_NAME"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!--      <b-form-group label="Общая сумма">-->
      <!--        &lt;!&ndash;        <validation-provider&ndash;&gt;-->
      <!--        &lt;!&ndash;          #default="{ errors }"&ndash;&gt;-->
      <!--        &lt;!&ndash;          name="Общая сумма"&ndash;&gt;-->
      <!--        &lt;!&ndash;          rules="required"&ndash;&gt;-->
      <!--        &lt;!&ndash;        >&ndash;&gt;-->
      <!--        <b-form-input-->
      <!--          v-model="value.total_sum"-->
      <!--          type="number"-->
      <!--        />-->
      <!--      &lt;!&ndash;          <small class="text-danger">{{ errors[0] }}</small>&ndash;&gt;-->
      <!--      &lt;!&ndash;        </validation-provider>&ndash;&gt;-->
      <!--      </b-form-group>-->

      <b-form-group
        label="Исполнители"
      >
        <validation-provider
          #default="{ errors }"
          name="Исполнители"
          rules="required"
        >
          <v-select
            v-model="users"
            :get-option-label="(el) => el.first_name + ' ' + el.last_name"
            multiple
            :options="userList.results"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <div
      v-if="modalVal.id"
      class="d-flex align-items-center"
    >
      <div
        class="rounded overflow-hidden w-75 card mb-0 border"
      >
        <div class="p-0 px-50 d-flex align-items-center justify-content-between border-bottom bg-light-secondary">
          <small>copy</small>
          <svg
            v-if="loader"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 12.9L7.14286 16.5L15 7.5"
              stroke="#28c76f"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20 7.5625L11.4283 16.5625L11 16"
              stroke="#28c76f"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <feather-icon
            v-else
            :icon="'CopyIcon'"
            class="cursor-pointer"
            @click="copy(secretKey.secret_key)"
          />
        </div>
        <b
          class="p-25 px-50 d-block overflow-hidden"
          style=" font-size: 10px"
          :class="secretKey.secret_key ? '' : 'text-center'"
        >
          <div
            v-if="isLoad"
            class="m-auto text-center"
          >
            <b-spinner />
          </div>
          <span v-else>{{ secretKey.secret_key ? secretKey.secret_key : 'Нет ключа' }}</span>
        </b>
      </div>

      <b-button
        size="sm"
        variant="primary"
        class="ml-1"
        @click="generateKey"
      >
        Генерировать
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, BButton, BSpinner,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    VSelect,
    BButton,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    modalVal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      isLoad: false,
      loader: false,
      value: {
        COMPANY_NAME: null,
      },
      users: [],
    }
  },
  computed: {
    ...mapState('users', ['userList']),
    ...mapState('portfolios', ['secretKey']),
  },
  methods: {
    ...mapActions('portfolios', ['CREATE_CLAIMANT_TYPE', 'UPDATE_CLAIMANT_TYPE', 'FETCH_SECRET_KEY', 'CREATE_KEY']),
    ...mapActions('users', ['FETCH_ALL_USERS']),

    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.$refs.claimantsValidation.validate()
        .then(success => {
          if (success) {
            const claimantTypeData = {
              users: this.users.map(e => e.id),
              ...this.value,
            }

            const updateClaimantType = this.modalVal.id
              ? this.UPDATE_CLAIMANT_TYPE({
                id: this.modalVal.COMPANY_ID,
                data: claimantTypeData,
              })
              : this.CREATE_CLAIMANT_TYPE(claimantTypeData)

            updateClaimantType.then(() => {
              this.$bvModal.hide('AddEditModal')
              this.value = {}
              this.$emit('refresh')
            })
          }
        })
    },

    openShowModal() {
      if (this.modalVal.id) {
        this.isLoad = true
        this.FETCH_SECRET_KEY({ company: this.modalVal.COMPANY_ID })
          .then(() => {
            this.isLoad = false
          })
          .catch(e => this.isLoad = false)
        this.value.COMPANY_NAME = this.modalVal.COMPANY_NAME
        this.users = this.modalVal.users
      } else {
        this.value = {
          COMPANY_NAME: null,
          total_sum: null,
        }
        this.users = []
      }

      this.FETCH_ALL_USERS({
        page_size: 500,
        role_unique_name: 'dk',
      })
    },

    async copy(s) {
      this.loader = true
      await navigator.clipboard.writeText(s)
        .then(() => {
          setTimeout(() => {
            this.loader = false
          }, 2000)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ключ скопирован',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => this.loader = false)
    },

    async generateKey() {
      this.isLoad = true
      try {
        await this.CREATE_KEY({ company: this.modalVal.COMPANY_ID })
        await this.FETCH_SECRET_KEY({ company: this.modalVal.COMPANY_ID })
      } catch (e) {
        this.isLoad = false
      }
      this.isLoad = false
    },

  },
}
</script>
